<nb-form-field>
  <input nbInput type="text"
         (click)="showDropdown()"
         readonly
         (blur)="onBlur()"
         fullWidth
         class="form-control input-sm"
         [fieldSize]="small ? 'small' : 'medium'"
         [style]="(minWidth ? 'min-width: 165px;' : '') + (small ? 'padding: 3px 16px; height: 32px; max-height: 32px;' : '') + (isMissionDetails ? 'color: #002244; background-color: white;' : '')"
         [disabled]="disabled"
         [placeholder]="placeholder"
         [value]="valuesNames"
         #dropdownInput>
  <nb-icon *ngIf="valuesNames"
           nbSuffix
           class="delete-icon icon-basic-color"
           icon="close-outline"
           pack="eva"
           (click)="clearSelectedItems()"></nb-icon>
</nb-form-field>

<!--<ng-content select="slnk-common-autocomplete-option">
</ng-content>-->
<!--<slnk-common-autocomplete-option-dropdown
  *ngIf="!selectItems;else staticComponent"
  [reference]="dropdownInput">
  &lt;!&ndash; TODO ADD GROUP OPTION &ndash;&gt;
  <slnk-common-autocomplete-option-list>
    <ng-content select="slnk-common-autocomplete-option, nb-option-group"
    ></ng-content>
  </slnk-common-autocomplete-option-list>
</slnk-common-autocomplete-option-dropdown>-->

<!--<ng-template #staticComponent>-->
<pv-common-autocomplete-option-dropdown
  [reference]="dropdownInput">
  <!-- TODO ADD GROUP OPTION -->
  <pv-common-autocomplete-option-list
    [isLoading]="isLoadingOptions"
    (onItemSelect)="selectItem($event)"
    (onItemsSelect)="selectMultipleItems($event)"
    [multiple]="multiple"
    [selectedItems]="valuesSet"
    [selectItems]="displayedItems"
    [newButton]="newButton"
    [deselectEntitiesButton]="deselectEntitiesButton"
    [newButtonText]="newButtonText"
    (newButtonClicked)="onNewButtonClicked()"
    [dropdown]="dropdown"
    (bottomReached)="onBottomReached()"
    (searchChanged)="onSearchChanged($event)">
  </pv-common-autocomplete-option-list>
</pv-common-autocomplete-option-dropdown>
<!--</ng-template>-->

